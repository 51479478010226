import React from 'react';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

//Translate
import Translate from './translate'

const Cardprice = ({name, subname, subname2, contenu, indexed, country}) => {
    const event = new CustomEvent('devis', { detail: indexed });
    return (
        <div className='shadow rounded-3 bg-white p-4 mm-4 text-start cardprice' style={{width: 400+"px"}}>
            <div className='border border-info border-0 border-bottom pb-2'>
                <h4 className='big upper'>
                    {name}
                </h4>
            </div>
            <div>
                <h4 className='big upper mt-3' style={{fontSize: "18px"}}>
                    {subname}
                </h4>
                <div>
                    {subname2}
                </div>
                <div className='pt-2'>
                    {contenu.map(added => {
                        return(
                            <div key={added} className="d-flex align-items-center ctn-price">
                                <Icon path={mdiCheck} className="m-1 text-success" style={{minWidth: 30+"px"}} height={30+"px"} width="30px"/> {added}
                            </div>
                        )
                    })}
                </div>
                <button type="button" className="btn btn-primary btn-lg btn-block w-100 mt-4" onClick={() => document.dispatchEvent(event)}><Translate country={country} keys={"demandedevis"}/></button>
            </div>
        </div>
    );
};

export default props => <Cardprice {...props}></Cardprice>;