import React from 'react';

import AnimatedNumber from "animated-number-react";

import Tag from './tag'

import check from '../assets/check.png'
import xperience from '../assets/xperience.png'
import Message from '../assets/message.png'

//Translate
import Translate from './translate'

const Why = ({name, desc, img, country}) => {
    const formatValue = (value) => value.toFixed(0);
    const stat =  <Translate country={country} keys={"stat"}/>
    return (
        <div className='text-center pt-5' style={{marginTop: 50+"px"}}>
            <div style={{marginBottom: 50+"px"}}>
                <Tag name={stat} color="blue" bgcolor="bgblue"></Tag>
            </div>
            <div className="d-flex shadow-sm justify-content-around m-auto why pt-2 why rounded-big">
                <div className='cards-header d-flex flex-column align-items-center p-4' style={{width: "200px"}}>
                    <img src={check} className="ico-header" width="80px" alt="" />
                    <div className='m-2 mb-0 big' style={{fontSize: 34+"px"}}>
                        <AnimatedNumber
                            value={2}
                            duration={1000}
                            formatValue={formatValue}
                        />
                        +
                    </div>
                    <div className='text-muted' style={{fontWeight: 400, fontSize: 16+"px"}}>
                        <Translate country={country} keys={"exp"}/>
                    </div>
                </div>
                <div className='cards-header d-flex flex-column align-items-center p-4' style={{width: "200px"}}>
                    <img src={xperience} className="ico-header" width="80px" alt="" />
                    <div className='m-2 mb-0 big' style={{fontSize: 34+"px"}}>
                        <AnimatedNumber
                            value={7}
                            duration={1000}
                            formatValue={formatValue}
                        />
                    </div>
                    <div className='text-muted' style={{fontWeight: 400, fontSize: 16+"px"}}>
                        <Translate country={country} keys={"project"}/>
                    </div>
                </div>
                <div className='cards-header d-flex flex-column align-items-center p-4' style={{width: "200px"}}>
                    <img src={Message} className="ico-header" width="80px" height="80px" alt="" />
                    <div className='m-2 mb-0 big' style={{fontSize: 34+"px"}}>
                        <AnimatedNumber
                            value={5}
                            duration={1000}
                            formatValue={formatValue}
                        />
                    </div>
                    <div className='text-muted' style={{fontWeight: 400, fontSize: 16+"px"}}>
                        <Translate country={country} keys={"positif"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default props => <Why {...props}></Why>;