import React, { useState, useEffect } from 'react';

import Tag from './tag'
import Avis from './avis'

//images profile
import Ana from '../assets/ana.png'
import pat from '../assets/pat.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

//Translate
import Translate from './translate'

const Whatabout = ({name, desc, img, country}) => {
    const avis = [
        {
            name: "Ana kdj",
            rate: 5,
            id: "Ana",
            picture: {Ana},
            desc: "Le projet réalisé correspond en tout point à mes attentes. Il a su être à l'écoute. Grâce à Gwendal, j'ai pu porter mon projet et le concrétiser en tout confiance et sérénité. Je recommande !"
        },
        {
            name: "Patrice despeyroux",
            rate: 5,
            id: "pat",
            picture: {pat},
            desc: "Personne à l'écoute, rigoureux qui aime les défis. Je recommande."
        }
    ]

    const avi = <Translate country={country} keys={"positif"}/>

    return (
        <div className='w-100 text-center' id='whatabout' style={{marginTop: 80+"px"}}>
            <Tag name={avi} color="blue" bgcolor="bgblue"></Tag>
            <div className="d-flex justify-content-center flex-column">
                <h2>
                    <Translate country={country} keys={"aviscli"}/>
                </h2>
                <div className='w-100'>
                    <Swiper
                        slidesPerView={"auto"}
                        grabCursor={true}
                        pagination={true}
                        modules={[Pagination]}
                        className="mySwiper m-auto avisswip"
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                    {
                        avis.map(avi => {
                            return(
                                <SwiperSlide>
                                    <Avis avis={avi}></Avis>
                                </SwiperSlide>
                            )
                        })
                    }
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default props => <Whatabout {...props}></Whatabout>;