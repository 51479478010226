import React from 'react';

import Tag from './tag'
import Card from './card'

import Maquette from '../assets/maquette.png'
import Target from '../assets/target.png'
import Web from '../assets/web.png'

//Translate
import Translate from './translate'

const Services = ({name, desc, img, country}) => {
    const title1 = <Translate country={country} keys={"cresite"}/>
    const title2 = <Translate country={country} keys={"devspe"}/>
    const title3 = <Translate country={country} keys={"cremaq"}/>
    const desc1 = <Translate country={country} keys={"cresitedesc"}/>
    const desc2 = <Translate country={country} keys={"devspedesc"}/>
    const desc3 = <Translate country={country} keys={"cremaqdesc"}/>
    return (
        <div className='text-center services' id='services'>
            <Tag name="Services" color="blue" bgcolor="bgblue"></Tag>
            <h2>
                <Translate country={country} keys={"propose"}/>
            </h2>
            <div className="d-flex justify-content-center ceque" style={{marginTop: 30+"px"}}>
                <Card name={title1} desc={desc1} img={Web}></Card>
                <Card name={title2} desc={desc2} img={Target}></Card>
                <Card name={title3} desc={desc3} img={Maquette}></Card>
            </div>
        </div>
    );
};

export default props => <Services {...props}></Services>;