import React from 'react';

import Tag from './tag'
import Cardprice from './cardprice'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

//Translate
import Translate from './translate'

const Product = ({name, desc, img, country}) => {
    const product1 = [<Translate country={country} keys={"siteresp"}/>, <Translate country={country} keys={"textimg"}/>, <Translate country={country} keys={"heberg"}/>, <Translate country={country} keys={"domaine"}/>, <Translate country={country} keys={"formcontact"}/>, <Translate country={country} keys={"miseheberg"}/>, <Translate country={country} keys={"formSite"}/>, <Translate country={country} keys={"pwa"}/>]
    const product2 = [<Translate country={country} keys={"siteresp"}/>, <Translate country={country} keys={"textimg"}/>, <Translate country={country} keys={"heberg"}/>, <Translate country={country} keys={"domaine"}/>, <Translate country={country} keys={"formcontact"}/>, <Translate country={country} keys={"miseheberg"}/>, <Translate country={country} keys={"formSite"}/>]
    const product3 = [<Translate country={country} keys={"maintsite"}/>, <Translate country={country} keys={"allweb"}/>]
    const product4 = [<Translate country={country} keys={"palettecouleur"}/>, <Translate country={country} keys={"crelogo"}/>, <Translate country={country} keys={"crecharte"}/>, <Translate country={country} keys={"maq"}/>]

    const mesPrest = <Translate country={country} keys={"mesPrest"}/>

    const h1 = <Translate country={country} keys={"siteperso"}/>
    const h2 = <Translate country={country} keys={"sitestandard"}/>
    const h3 = <Translate country={country} keys={"cremaq"}/>
    const h4 = <Translate country={country} keys={"devspe"}/>
    const sub1 = <Translate country={country} keys={"onmesure"}/>
    const sub2 = <Translate country={country} keys={"wordpress"}/>
    const sub3 = <Translate country={country} keys={"figma"}/>

    return (
        <div className='text-center' id='products' style={{marginTop: 100+"px"}}>
            <Tag name={mesPrest} color="blue" bgcolor="bgblue"></Tag>
            <div className="d-flex justify-content-center flex-column">
                <h2>
                    <Translate country={country} keys={"chooseoffer"}/>
                </h2>
                <div className='d-flex justify-content-center align-items-start w-100'>
                    <Swiper
                        slidesPerView={"auto"}
                        grabCursor={true}
                        pagination={true}
                        modules={[Pagination]}
                        className="mySwiper"
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide><Cardprice country={country} name={h1} indexed={0} subname={sub1} contenu={product1}></Cardprice></SwiperSlide>
                        <SwiperSlide><Cardprice country={country} name={h2} indexed={1} subname={sub2}  contenu={product2}></Cardprice></SwiperSlide>
                        <SwiperSlide>
                            <div className='d-flex flex-column'>
                                <Cardprice country={country} name={h3} indexed={2} subname={sub3} contenu={product4}></Cardprice>
                                <Cardprice country={country} name={h4} indexed={3} contenu={product3}></Cardprice>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default props => <Product {...props}></Product>;