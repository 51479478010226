import React from 'react';

const Tag = ({name, color, bgcolor}) => {
    return (
        <span className={color + ' ' + bgcolor + " badge-primary"}>
            {name}
        </span>
    );
};

export default props => <Tag {...props}></Tag>;