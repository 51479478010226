import React from 'react';

import Logo from '../components/Logo'
import insta from '../assets/insta.webp'
import Malt from '../assets/malt.png'
import linkedin from '../assets/LinkedIN.png'

//Translate
import Translate from './translate'

const Footer = ({country}) => {
    return (
        <footer id='footer' className='mt-55' style={{fontSize: 16+'px'}}>
            <div className='d-flex justify-content-between w-100 border-bottom pb-4 align-items-end'>
                <div className='pt-3 navig'>
                    <h4>
                        Navigation
                    </h4>
                    <div className='d-flex flex-wrap wraptest' style={{fontSize: 20+"px", width: "200px"}}>
                        <span className='mt-4 mb-1' onClick={() => scroll(0, document.getElementById("root").offsetTop - 110)} style={{width: "100px"}}>
                            <Translate country={country} keys={"home"}/>
                        </span>
                        <span className='mt-4 mb-1' onClick={() => scroll(0, document.getElementById("about").offsetTop - 110)} style={{width: "100px"}}>
                            <Translate country={country} keys={"about"}/>
                        </span>
                        <span className='mt-2 mb-1' onClick={() => scroll(0, document.getElementById("work").offsetTop - 110)} style={{width: "100px"}}>
                            Portfolio
                        </span>
                        <span className='mt-2 mb-1' onClick={() => scroll(0, document.getElementById("products").offsetTop - 110)} style={{width: "100px"}}>
                            <Translate country={country} keys={"prest"}/>
                        </span>
                    </div>
                </div>
                <div className='d-flex flex-column footer-2'>
                    <div className='ctn-logo'>
                        <Logo></Logo>
                    </div>
                    <div className='rs d-flex rs-2'>
                        <a href="https://www.linkedin.com/in/gwendal-le-floch-66a3361b7/" target="_blank" rel="noopener noreferrer" style={{paddingTop: "10px", marginLeft: "0.5rem"}}>
                            <img src={linkedin} alt="" width={46+"px"} />
                        </a>
                        <a href="https://www.instagram.com/gwendiish/" target="_blank" rel="noopener noreferrer" style={{paddingTop: "10px"}}>
                            <img src={insta} alt="" width={46+"px"} />
                        </a>
                        <a href="https://www.malt.fr/profile/gwendallefloch" target="_blank" rel="noopener noreferrer" style={{paddingTop: "10px"}}>
                            <img src={Malt} alt="malt" className='rounded-circle' width={46+"px"} />
                        </a>
                    </div>
                </div>
            </div>
            <div className='text-muted pt-2 pb-2 foot'>
                <span>
                    Copyright © 2022 Gwendal Le Floch | Tous droits réservé à Gwendal Le Floch
                </span>
            </div>
        </footer>
    );
};

export default Footer;