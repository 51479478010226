import React from 'react';

const Translate = ({country, keys}) => {
    const fr = {
        devis: "Obtenir un devis",
        home: "Accueil",
        about: "A propos",
        prest: "Prestations",
        h1: "Faites briller votre entreprise sur le web",
        subtitle: "Depuis plus de 2 ans je réalise des sites pour particuliers et petites entreprises en passant de la création des maquettes et du développement de site web jusqu'à l'hébergement",
        find: "Me découvrir",
        project: "Projets",
        exp: "Années d'experience",
        positif: "Avis",
        propose: "Ce que je vous propose",
        cresite: "Création de site web",
        cresitedesc: "Je crée votre site web sur mesure",
        devspe: "Développement spécifique",
        devspedesc: "Je vous propose mes services sur un tarif journalier pour des besoins spécifiques (maintenance, debugage, etc)",
        cremaq: "Création de maquettes",
        cremaqdesc: "je crée vos maquettes après avoir défini avec vous vos besoins et vos envies",
        moreMe: "Apprenez-en plus sur moi",
        stat: "Statistiques",
        work: "Mon travail",
        lastproj: "Derniers projets réalisés",
        about1: "Ma passion pour le développement web commence en 2015, pendant mon cursus scolaire j'ai l'occasion de faire mes premiers sites et ça me plaît énormément.",
        about2: "Ensuite je consacre beaucoup de mon temps libre sur des projets personnels pour me former en plus de ma formation scolaire.",
        about3: "En 2019 j'obtiens ma première expèrience en tant que développeur web.",
        about4: "Depuis 2021 je me suis lancé dans le freelance en plus de mon alternance en tant que développeur front-end.",
        stat: "Statistiques",
        name1: "Plateforme collaborative",
        name2: "Plateforme mini-jeux multijoueur",
        name3: "Application de commande",
        name4: "Réservation de logement",
        name5: "Bon plans jeune",
        name7: "Web documentaire animalier",
        name8: "Application android",
        name6: "Site vitrine coach sportif",
        desc1: "De la demande de dépannage ou de services à la personne aux travaux les plus complexes, en passant par la location de matériel, Coup d'pouce permet en quelques minutes de localiser l'ensemble des habitants et professionnels à proximité, susceptibles de répondre à tout type de besoins",
        desc2: "Micro-games est une plateforme de mini jeux sur laquelle tu peux jouer avec tes amis de 2 à 10 joueurs. Tous les jeux sont rapides à prendre en main et ont une bonne durabilité.",
        desc3: "Vous souhaitez réserver votre plat pour le lendemain, dans plusieurs jours ou même tout de suite ? Cette application vous permet de pouvoir le faire à tout moment.",
        desc4: "Création de la base de données, de toute les maquettes, mise en place du système de commande, plannification de ces dernières et création de la partie administrative permettant d'administrer les plats et commandes de cette application",
        desc5: "Cette plateforme permet la réservation de biens de particulier à particulier. On peux y retrouver les profils des différents vendeurs avec leurs biens et leurs avis client.",
        desc6: "Stud’LP est un projet que j’ai réalisé avec un groupe d’étudiants pour la nuit de l’info. Ce site à pour objectif de regrouper tous les bons plan pour les étudiants en allant des applications astucieuse pour faire des économies, j'usqu'aux aides qu'ils peuvent bénéficier.",
        desc7: "Site vitrine permettant la mise en relation entre le professionnel et ces clients via un formulaire de contact, mise en avant des préstations.",
        desc8: 'kabby quiz est une application de qui pour tablette permettant à des usagers de pouvoir "passer le temps" pendant leurs trajets en taxi',
        task1: "Mise en place d'une map custom, création de la base de données, création des comptes vendeur / prestataire, système de paiement, ...",
        task2: "Mise en place de socket io, création de lobby, création des jeux, élaboration des maquettes, customisation des skins.",
        task3: "Création de la base de données, de toute les maquettes, mise en place du système de commande, plannification de ces dernières et création de la partie administrative permettant d'administrer les plats et commandes de cette application",
        task4: "Mise en place de react et react router, création des composants réutilisable, mise en place du responsive.",
        task5: "Création des maquettes, création de composants réutilisable, mise en place du formulaire de contact, mise en place de l'hebergement et de la base de données",
        task6: "Création d'un quiz pour tablette. Mise en place du système de classement, de l'adiministration des quiz et du système de pub.",
        chooseoffer: "Choisissez votre prestation",
        siteresp: "Site responsive (adapté à tous supports)",
        textimg: "Mise en place du contenu (textes & images)",
        heberg: "Mise en ligne du site",
        domaine: "Nom de domaine + hebergement + e-mail personalisé (12 mois)",
        formcontact: "Formulaire de contact & map",
        miseheberg: "mise en place de l'hebergement",
        formSite: "formation complète sur l'utilisation du site",
        mesPrest: "Mes prestations",
        pwa: "Création d'une PWA (application téléchargeable depuis le navigateur)",
        demandedevis: "Demander un devis",
        siteperso: "SITE VITRINE PERSONALISÉ",
        sitestandard: "SITE VITRINE STANDARD",
        cremaq: "CRéATION DE MAQUETTES",
        devspe: "DÉVELOPPEMENTS SPÉCIFIQUES",
        onmesure: "Développé SUR-MESURE",
        wordpress: "Développé sous WORDPRESS",
        figma: "Sous Figma / Adobe XD",
        palettecouleur: "Création de palettes de couleurs",
        crelogo: "Création de logo",
        crecharte: "Choix de charte graphique",
        maq: "Maquettes au format PSD, EPS, XD, PNG",
        maintsite: "Maintenance de site web",
        allweb: "Tous type de développement web",
        contactme: "Me contacter",
        aviscli: "Avis clients"
    }

    const en = {
        devis: "Get a quote",
        home: "Home",
        about: "About",
        prest: "Pricing",
        h1: "Make your business shine on the web",
        subtitle: "For more than 2 years I have been creating sites for individuals and small businesses, from the creation of models and website development to hosting",
        find: "Let's get started",
        project: "Projects",
        exp: "Years Experience",
        positif: "Reviews",        
        propose: "What I offer you",
        cresite: "Website creation",
        cresitedesc: "I create your custom website",
        devspe: "Specific development",
        devspedesc: "I offer my services on a daily rate for specific needs (maintenance, debugging, etc.)",
        cremaq: "Creation of models",
        cremaqdesc: "I create your models after having defined with you your needs and your desires",
        moreMe: "Learn more about me",
        stat: "Statistics",
        work: "My work",
        lastproj: "Last completed projects",
        about1: "My passion for web development began in 2015, during my school studies I had the opportunity to make my first sites and I really liked it.",
        about2: "Then I spend a lot of my free time on personal projects to train myself in addition to my school training.",
        about3: "In 2019 I get my first experience as a web developer.",
        about4: "Since 2021 I have started freelancing in addition to my work-study program as a front-end developer.",
        stat: "Statistics",
        name1: "Collaborative platform",
        name2: "Multiplayer mini-game platform",
        name3: "Application de commande",
        name4: "Order app",
        name5: "Good young plans",
        name7: "Wildlife web documentary",
        name8: "Android application",
        name6: "Sports coach showcase website",
        desc1: "From the request for repairs or personal services to the most complex works, including the rental of equipment, Coup d'pouce makes it possible in a few minutes to locate all the inhabitants and professionals in the vicinity, likely to respond to any kind of needs",
        desc2: "Micro-games is a platform of mini games on which you can play with your friends from 2 to 10 players. All games are quick to pick up and have good durability.",
        desc3: "Do you want to book your dish for the next day, in several days or even right away? This application allows you to be able to do it at any time.",
        desc4: "Creation of the database, of all the models, implementation of the ordering system, planning of the latter and creation of the administrative part allowing to administer the dishes and orders of this application",
        desc5: "This platform allows the reservation of goods from individual to individual. You can find the profiles of the different sellers with their properties and their customer reviews.",
        desc6: "Stud'LP is a project I did with a group of students for the info night. This site aims to bring together all the good plans for students, ranging from clever applications to save money, to the aid they can benefit from.",
        desc7: "Showcase site allowing the connection between the professional and these customers via a contact form, highlighting the services.",
        desc8: 'kabby quiz is a quiz application for tablets allowing users to "pass the time" during their taxi journeys',
        task1: "Implementation of a custom map, creation of the database, creation of seller / service provider accounts, payment system, ...",
        task2: "Implementation of socket io, creation of lobby, creation of games, development of models, customization of skins.",
        task3: "Creation of the database, of all the models, implementation of the ordering system, planning of the latter and creation of the administrative part allowing to administer the dishes and orders of this application",
        task4: "Implementation of react and react router, creation of reusable components, implementation of responsive.",
        task5: "Creation of models, creation of reusable components, implementation of the contact form, implementation of hosting and database",
        task6: "Creation of a quiz for tablet. Implementation of the ranking system, the administration of quizzes and the pub system.",
        chooseoffer: "Choose your service",
        siteresp: "Responsive site (suitable for all media)",
        textimg: "Setting up content (texts & images)",
        heberg: "Launch of the site",
        domaine: "Domain name + hosting + personalized email (12 months)",
        formcontact: "Contact form & map",
        miseheberg: "setting up accommodation",
        formSite: "complete training on the use of the site",
        mesPrest: "my services",
        pwa: "Creation of a PWA (downloadable application from the browser)",
        demandedevis: "Ask for a quote",
        siteperso: "PERSONALIZED SHOWCASE SITE",
        sitestandard: "STANDARD SHOWCASE SITE",
        cremaq: "CREATION OF MODELS",
        devspe: "SPECIFIC DEVELOPMENTS",
        onmesure: "CUSTOM developed",
        wordpress: "Developed under WORDPRESS",
        figma: "Under Figma / Adobe XD",
        palettecouleur: "Creation of color palettes",
        crelogo: "Brand design",
        crecharte: "Choice of graphic charter",
        maq: "Mockups in PSD, EPS, XD, PNG",
        maintsite: "Website maintenance",
        allweb: "All types of web development",
        contactme: "Contact me",
        aviscli: "Clients reviews"
    }

    const trans = function() {
        if (country === "FR") {
            return fr[keys]
        }
        return en[keys]
    }

    return trans();
};

export default props => <Translate {...props}></Translate>;