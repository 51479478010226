import React from 'react';

import Icon from '@mdi/react';
import { mdiStar } from '@mdi/js';

const Avis = ({avis}) => {
    return (
        <div className='border rounded-3 d-flex flex-column justify-content-between bg-white avis-card' style={{height: 350 + "px", width: 300+"px", margin: "20px", padding: "20px"}}>
            <div className='text-start'>
                {avis.desc}
            </div>
            <div className='d-flex align-items-center'>
                <img src={avis.picture[avis.id]} width="50px" height={50+"px"} alt="" />
                <div className='d-flex flex-column align-items-start' style={{paddingLeft: 20+"px"}}>
                    <div className='text-primary semibold' style={{fontSize: 18+"px"}}>
                        {avis.name}
                    </div>
                    <div>
                        {
                            Array.from(Array(5), (e, i) => {
                                if (i <= avis.rate - 1) {
                                    return <Icon path={mdiStar} className="rate" height={30+"px"}/>
                                } else {
                                    return <Icon path={mdiStar} className="norate" height={30+"px"}/>
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default props => <Avis {...props}></Avis>;