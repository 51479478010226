import React from 'react';

import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';

import Torm from '../assets/torm.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

const ProjectDetail = ({detail}) => {
    const event = new CustomEvent('work', { detail: false });
    return (
        <div className='position-fixed d-flex align-items-center h-100 detailProject justify-content-center'>
            <div className='position-absolute w-100 h-100 left-0 top-0 transopac'></div>
            <div className='bg-white rounded-big d-flex position-relative topMid'>
                <Icon path={mdiCloseCircle} height={40+"px"} className="cursor-pointer closeCircle m-1 position-absolute top-0 right-0 m-3" onClick={() => document.dispatchEvent(event)}/>
                <div className='left'>
                    <div className='text-primary nameWork mt-0'>
                        {detail.name}
                    </div>
                    <h2 className='mt-2'>
                        {detail.desc}
                    </h2>
                    <Swiper
                        slidesPerView={"auto"}
                        grabCursor={true}
                        pagination={true}
                        modules={[Pagination]}
                        className="mySwiper w-100"
                    >
                        {detail.slide.map(img => {
                            return(
                                <SwiperSlide className='w-100 m-auto'>
                                    <img src={img} style={{maxWidth: "100%", maxHeight: "400px"}} height={"auto"} alt="" />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
                <div className='right'>
                    <div>
                        <h4>Programming languages</h4>
                        <div className='d-flex pt-3 pb-3'>
                            {detail.languages.map(language => {
                                return(
                                    <div className='shadow p-2 mr-2'>
                                        <img src={language} width="40px" height="40px" alt="" />
                                    </div>
                                )
                            })}
                        </div>
                        <h4>
                            Description
                        </h4>
                        <div>
                            {detail.describ}
                        </div>
                        {detail.tasks ? 
                            <div>
                                <h4 className='mt-3'>
                                    Tâches
                                </h4>
                                <div>
                                    {detail.tasks}
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default props => <ProjectDetail {...props}></ProjectDetail>;