import React from 'react';

import Tag from './tag'

import GLF2 from '../assets/glf2.png'

//Translate
import Translate from './translate'

const About = ({name, desc, img, country}) => {

    const about = <Translate country={country} keys={"about"}/>
    return (
        <div className='w-100 pt-5' id='about' style={{marginTop: 50+"px"}}>
            <div className='d-flex align-items-center m-auto' style={{maxWidth: 1400+"px"}}>
                <div className='abbout'>
                    <img src={GLF2} style={{maxWidth: 100+"%"}} alt="" />
                </div>
                <div className='abbout2'>
                    <Tag name={about} color="blue" bgcolor="bgblue"></Tag>
                    <div className='text-start'>
                        <h2>
                            <Translate country={country} keys={"moreMe"}/>
                        </h2>
                        <div>
                            <div>
                                <Translate country={country} keys={"about1"}/>
                            </div>
                            <br/>
                            <div>
                                <Translate country={country} keys={"about2"}/>
                            </div>
                            <br/>
                            <div>
                                <Translate country={country} keys={"about3"}/>
                            </div>
                            <br/>
                            <div>
                                <Translate country={country} keys={"about4"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default props => <About {...props}></About>;