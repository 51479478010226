import React from 'react';

import Tag from './tag'
import Cardwork from './cardWork'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

import first from '../assets/1.png'
import deux from '../assets/2.png'
import trois from '../assets/3.png'
import quatre from '../assets/4.png'
import cinq from '../assets/5.png'
import six from '../assets/6.png'
import sept from '../assets/7.png'
import huit from '../assets/8.png'

// import required modules
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

//Translate
import Translate from './translate'

const Work = ({name, desc, img, country}) => {
    const name1 = <Translate country={country} keys={"name1"}/>
    const name2 = <Translate country={country} keys={"name2"}/>
    const name3 = <Translate country={country} keys={"name3"}/>
    const name4 = <Translate country={country} keys={"name4"}/>
    const name5 = <Translate country={country} keys={"name5"}/>
    const name6 = <Translate country={country} keys={"name6"}/>
    const name7 = <Translate country={country} keys={"name7"}/>
    const name8 = <Translate country={country} keys={"name8"}/>
    const work = <Translate country={country} keys={"work"}/>
    return (
        <div style={{marginTop: 80+"px"}} id="work">
            <Tag name={work} color="blue" bgcolor="bgblue"></Tag>
            <div className="d-flex justify-content-center flex-column">
                <div className='d-flex justify-content-between align-items-center'>
                    <h2>
                        <Translate country={country} keys={"lastproj"}/>
                    </h2>
                </div>
                <div className='ctn-proj' style={{width: "calc(100% + 50px)"}}>
                    <Swiper
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    loop={true}
                    grabCursor={true}
                    pagination={true}
                    modules={[Pagination]}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide><Cardwork name={name1} desc="Coup d'pouce" img={first}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name2} desc='Micro games' img={deux}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name3} desc='Sendo sushi' img={trois}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name4} desc='Kasa' img={quatre}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name5} desc="Stud'lp" img={cinq}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name6} desc='Outsider' img={six}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name7} desc='Save us' img={sept}></Cardwork></SwiperSlide>
                        <SwiperSlide><Cardwork name={name8} desc='Kabby quiz' img={huit}></Cardwork></SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default props => <Work {...props}></Work>;