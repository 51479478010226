import React, { useState, useEffect } from 'react';
import Logo from '../components/Logo'

import Icon from '@mdi/react';
import { mdiEmail, mdiChevronDown } from '@mdi/js';

import { US, FR, GB } from 'country-flag-icons/react/3x2'

//Translate
import Translate from './translate'

const Navbar = ({countrys}) => {
    const [nav, setNav] = useState([]);
    const [on, setOn] = useState(0);
    const [countryShow, setCountryShow] = useState(false);

    useEffect(() => {        
        window.addEventListener('scroll', function(e) {
            let array = []
            array[0] = document.getElementById("root").offsetTop - 110
            array[1] = document.getElementById("about").offsetTop - 110
            array[2] = document.getElementById("work").offsetTop - 110
            array[3] = document.getElementById("products").offsetTop - 110

            setNav(array)

            const current = window.pageYOffset
            array.map((ancre, index) => {
                if (ancre - 120 < current) {
                    if (index !== 3 && nav[index+1] > current) {
                        setOn(index)
                    }else{
                        setOn(index)
                    }
                }
            })
        })
    }, []);

    const setCountry = function(val) {
        const event = new CustomEvent('language', { detail: val });
        document.dispatchEvent(event);
    }

    return (
        <div className='d-flex align-items-center justify-content-between' id='navbar' style={{fontSize: 16+'px'}}>
            <div className='d-flex align-items-center'>
                <Logo></Logo>
                <ul className='d-flex align-items-center cursor-pointer' style={{fontWeight: 600}}>
                    <li onClick={() => scroll(0, document.getElementById("root").offsetTop - 110)} className={on === 0 ? 'border-0 p-4 big' : 'border-0 p-4'}><Translate country={countrys} keys={"home"}/></li>
                    <li onClick={() => scroll(0, document.getElementById("about").offsetTop - 110)} className={on === 1 ? 'border-0 p-4 big' : 'border-0 p-4'}><Translate country={countrys} keys={"about"}/></li>
                    <li onClick={() => scroll(0, document.getElementById("work").offsetTop - 110)} className={on === 2 ? 'border-0 p-4 big' : 'border-0 p-4'}>Portfolio</li>
                    <li onClick={() => scroll(0, document.getElementById("products").offsetTop - 110)} className={on === 3 ? 'border-0 p-4 big' : 'border-0 p-4'}><Translate country={countrys} keys={"prest"}/></li>
                </ul>
            </div>
            <div className='d-flex align-items-center'>
                <a href="mailto:contact@gwendal-lefloch.com" className='pressed'>
                    <div className='border-2 border border-primary bg-white rounded-pill p-2 m-2 d-flex align-items-center'>
                        <Icon path={mdiEmail} className="m-1 text-primary" height={30+"px"}/>
                        contact@gwendal-lefloch.com
                    </div>
                </a>
                <a className='pressed'>
                    <div className='button-quote text-white p-3 m-2 rounded-pill position-relative bolden' onClick={() => scroll(0, document.getElementById("contact").offsetTop - 110)}>
                        <span>
                            <Translate country={countrys} keys={"devis"}/>
                        </span>
                    </div>
                </a>
                <div className='d-flex align-items-center position-relative cursor-pointer p-2' onClick={() => setCountryShow(!countryShow)}>
                    {countrys === "FR" ? <FR title="United States" height={22+"px"} className="..."/> : <GB title="United States" height={22+"px"}/>}
                    <Icon path={mdiChevronDown} height={30+"px"} className="m-1 text-primary"/>
                    {countryShow === true ?
                        <div className='rounded-3 position-absolute country-details bg-white shadow-sm overflow-hidden' id='country-detail'>
                            <div className={countrys === "FR" ? "d-flex p-3 filter-opac" : "d-flex p-3 cursor-pointer hovered"} onClick={() => setCountry("FR")}>
                                <FR title="United States" height={22+"px"} style={{marginRight: 10+"px"}}/>
                                French
                            </div>
                            <div className={countrys === "GB" ? "d-flex p-3 filter-opac" : "d-flex p-3 cursor-pointer hovered"} onClick={() => setCountry("GB")}>
                                <GB title="United States" height={22+"px"} style={{marginRight: 10+"px"}}/>
                                English
                            </div>
                        </div> 
                    : ""}
                </div>
            </div>
        </div>
    );
};

export default Navbar;