import React, { useState, useEffect } from 'react';

const Cardwork = ({name, desc, img}) => {
    console.log(desc)
    const event = new CustomEvent('work', { detail: desc });

    return (
        <div className='d-flex flex-column p-3 mb-2 text-start workc' style={{width: 400+"px"}} onClick={() => document.dispatchEvent(event)}>
            <img src={img} width="100%" style={{borderRadius: '15px'}} alt="" />
            <div className='text-primary nameWork'>
                {name}
            </div>
            <div className='big' style={{fontSize: "28px"}}>
                {desc}
            </div>
        </div>
    );
};

export default props => <Cardwork {...props}></Cardwork>;