import React, { useState, useEffect } from 'react';

import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Services from "../components/services"
import Why from "../components/why"
import Work from "../components/work"
import Product from "../components/product"
import Whatabout from "../components/whatAbout"
import About from "../components/about"
import Footer from '../components/footer'
import Contact from '../components/contact'

import Icon from '@mdi/react';
import { mdiEmail, mdiChevronDown } from '@mdi/js';

//detail
import DetailProject from '../components/projectDetail'

//coup de pouce
import cdp1 from '../assets/cdp1.png'
import cdp2 from '../assets/cdp2.png'
import cdp3 from '../assets/cdp3.png'
import cdp4 from '../assets/cdp4.png'
const slidePouce=[cdp1, cdp2, cdp3, cdp4]
//micro games
import micro1 from '../assets/micro1.PNG'
import micro2 from '../assets/micro2.PNG'
import micro3 from '../assets/micro3.PNG'
const slidemicro=[micro1, micro2, micro3]
//sendo
import sendo1 from '../assets/sendo1.png'
import sendo2 from '../assets/sendo2.png'
import sendo3 from '../assets/sendo3.png'
const slidesendo=[sendo1, sendo2, sendo3]
// //kasa
import kasa1 from '../assets/kasa1.png'
import kasa2 from '../assets/kasa2.png'
import kasa3 from '../assets/kasa3.png'
const slidekasa=[kasa1, kasa2, kasa3]
// //stud lp
import stud1 from '../assets/stud1.png'
import stud2 from '../assets/stud2.png'
import stud3 from '../assets/stud3.png'
const slidestud=[stud1, stud2, stud3]
// //outsider
import out1 from '../assets/out1.png'
import out2 from '../assets/out2.png'
import out3 from '../assets/out3.png'
const slideoutsid=[out1, out2, out3]
// //save us
import save1 from '../assets/save1.png'
import save2 from '../assets/save2.png'
import save3 from '../assets/save3.png'
const slidesave=[save1, save2, save3]

// //kabby
import kabby1 from '../assets/acc.jpg'
import kabby2 from '../assets/lion.jpg'
import kabby3 from '../assets/ours.jpg'
import kabby4 from '../assets/pin.jpg'
const slidekabby =[kabby1, kabby2, kabby3, kabby4]

//stack
import Js from '../assets/js.png'
import Reactpng from '../assets/react.png'
import firebasepng from '../assets/firebase.png'
import socket from '../assets/socket.png'

//Translate
import Translate from '../components/translate'

import Logo from '../components/Logo'

import { US, FR, GB } from 'country-flag-icons/react/3x2'

const Home = ({ data }) => {
    const [header, setHeader] = useState("");
    const [navbar, setNavbar] = useState("");
    const [detail, setDetail] = useState(false);
    const [on, setOn] = useState(0);
    const [country, setCountry] = useState("FR");
    const [countryShow, setCountryShow] = useState(false);
    const [nav, setNav] = useState([]);
    const [showBurger, setShowBurger] = useState(false);

    const name1 = <Translate country={country} keys={"name1"}/>
    const name2 = <Translate country={country} keys={"name2"}/>
    const name3 = <Translate country={country} keys={"name3"}/>
    const name4 = <Translate country={country} keys={"name4"}/>
    const name5 = <Translate country={country} keys={"name5"}/>
    const name6 = <Translate country={country} keys={"name6"}/>
    const name7 = <Translate country={country} keys={"name7"}/>
    const name8 = <Translate country={country} keys={"name8"}/>
    
    const desc1 = <Translate country={country} keys={"desc1"}/>
    const desc2 = <Translate country={country} keys={"desc2"}/>
    const desc3 = <Translate country={country} keys={"desc3"}/>
    const desc4 = <Translate country={country} keys={"desc4"}/>
    const desc5 = <Translate country={country} keys={"desc5"}/>
    const desc6 = <Translate country={country} keys={"desc6"}/>
    const desc7 = <Translate country={country} keys={"desc7"}/>
    const desc8 = <Translate country={country} keys={"desc8"}/>

    const task1 = <Translate country={country} keys={"task1"}/>
    const task2 = <Translate country={country} keys={"task2"}/>
    const task3 = <Translate country={country} keys={"task3"}/>
    const task4 = <Translate country={country} keys={"task4"}/>
    const task5 = <Translate country={country} keys={"task5"}/>
    const task6 = <Translate country={country} keys={"task6"}/>

const details = [
    {
        slide: slidePouce,
        name: name1,
        desc: "Coup d'pouce",
        languages : [Js, Reactpng, firebasepng],
        describ: desc1,
        tasks: task1
    },
    {
        slide: slidemicro,
        name: name2,
        desc: "Micro games",
        languages : [Js, Reactpng, firebasepng, socket],
        describ: desc2,
        tasks: task2
    },    
    {
        slide: slidesendo,
        name: name3,
        desc: "Sendo sushi",
        languages : [Js, Reactpng, firebasepng],
        describ: desc3,
        tasks: task3
    },    
    {
        slide: slidekasa,
        name: name4,
        desc: "Kasa",
        languages : [Js, Reactpng],
        describ: desc4,
        tasks: task4
    },
    {
        slide: slidestud,
        name: name5,
        desc: "Stud'lp",
        languages : [Js, Reactpng],
        describ: desc5,
    },
    {
        slide: slideoutsid,
        name: name6,
        desc: "Outsider",
        languages : [Js, Reactpng, firebasepng],
        describ: desc6,
        tasks: task5
    },
    {
        slide: slidesave,
        name: name7,
        desc: "Save us",
        languages : [Js, Reactpng],
        describ: desc7,
    },
    {
        slide: slidekabby,
        name: name8,
        desc: "Kabby quiz",
        languages : [Js, Reactpng, firebasepng],
        describ: desc8,
        tasks: task6
    }
]

    useEffect(() => {
        document.addEventListener('language', function(e) {
            setCountry(e.detail)
        }, false);

        setNavbar(document.getElementById("navbar"))
        setHeader(document.getElementsByTagName("header")[0])

        window.addEventListener('scroll', function(e) {
            let array = []
            array[0] = document.getElementById("root").offsetTop - 110
            array[1] = document.getElementById("about").offsetTop - 110
            array[2] = document.getElementById("work").offsetTop - 110
            array[3] = document.getElementById("products").offsetTop - 110

            setNav(array)

            const current = window.pageYOffset
            array.map((ancre, index) => {
                if (ancre - 120 < current) {
                    if (index !== 3 && nav[index+1] > current) {
                        setOn(index)
                    }else{
                        setOn(index)
                    }
                }
            })
        })
    });

    document.addEventListener('work', function(e) {
        setDetail(e.detail)
    }, false);

    window.addEventListener('scroll', function(e) {
        let pos = window.scrollY
        if (pos !== 0 && header !== "") {
            navbar.classList.add('position-fixed', 'left-0', 'top-0', 'navfix', 'shadow-sm')
            document.getElementById('burger').classList.add('position-fixed', 'left-0', 'top-0', 'navfix', 'shadow-sm')
            header.style.paddingTop = 94+"px"
        } else if(pos === 0 && header !== "") {
            navbar.classList.remove('position-fixed', 'left-0', 'top-0', 'navfix', 'shadow-sm')
            document.getElementById('burger').classList.remove('position-fixed', 'left-0', 'top-0', 'navfix', 'shadow-sm')
            header.style.paddingTop = 0
        } 
    })

    return (
        <div className='home'>
            <div id="burger" className='align-items-center justify-content-between'>
                <div style={{width: "213px"}}>
                    <Logo></Logo>
                </div>
                <div className='d-flex flex-column align-items-end mr-2' onClick={() => setShowBurger(!showBurger)}>
                    <div className='burger-1'></div>
                    <div className='burger-2 mt-2'></div>
                </div>
            </div>
            <div className={!showBurger ? 'position-fixed d-none left-0 w-100 burger-desc fromMenu2' : "position-fixed left-0 w-100 burger-desc fromMenu2"}>
                <ul className='d-flex align-items-center cursor-pointer flex-column' style={{fontWeight: 600}} onClick={() => setShowBurger(!showBurger)}>
                    <li onClick={() => scroll(0, document.getElementById("root").offsetTop - 110)} className={on === 0 ? 'border-0 p-4 big' : 'border-0 p-4'}><Translate country={country} keys={"home"}/></li>
                    <li onClick={() => scroll(0, document.getElementById("about").offsetTop - 110)} className={on === 1 ? 'border-0 p-4 big' : 'border-0 p-4'}><Translate country={country} keys={"about"}/></li>
                    <li onClick={() => scroll(0, document.getElementById("work").offsetTop - 110)} className={on === 2 ? 'border-0 p-4 big' : 'border-0 p-4'}>Portfolio</li>
                    <li onClick={() => scroll(0, document.getElementById("products").offsetTop - 110)} className={on === 3 ? 'border-0 p-4 big' : 'border-0 p-4'}><Translate country={country} keys={"prest"}/></li>
                </ul>
                <div className='d-flex align-items-center flex-column'>
                    <a href="mailto:contact@gwendal-lefloch.com" className='pressed' onClick={() => setShowBurger(!showBurger)}>
                        <div className='border-2 border border-primary bg-white rounded-pill p-2 m-2 d-flex align-items-center'>
                            <Icon path={mdiEmail} className="m-1 text-primary" height={30+"px"}/>
                            contact@gwendal-lefloch.com
                        </div>
                    </a>
                    <a className='pressed' onClick={() => setShowBurger(!showBurger)}>
                        <div className='button-quote text-white p-3 m-2 rounded-pill position-relative bolden' onClick={() => scroll(0, document.getElementById("contact").offsetTop - 110)}>
                            <span>
                                <Translate country={country} keys={"devis"}/>
                            </span>
                        </div>
                    </a>
                    <div className='d-flex align-items-center position-relative cursor-pointer p-2' onClick={() => setCountryShow(!countryShow)}>
                        {country === "FR" ? <FR title="United States" height={22+"px"} className="..."/> : <GB title="United States" height={22+"px"}/>}
                        <Icon path={mdiChevronDown} height={30+"px"} className="m-1 text-primary"/>
                        {countryShow === true ?
                            <div className='rounded-3 position-absolute country-details bg-white shadow-sm overflow-hidden' onClick={() => setShowBurger(!showBurger)} id='country-detail'>
                                <div className={country === "FR" ? "d-flex p-3 filter-opac" : "d-flex p-3 cursor-pointer hovered"} onClick={() => setCountry("FR")}>
                                    <FR title="United States" height={22+"px"} style={{marginRight: 10+"px"}}/>
                                    French
                                </div>
                                <div className={country === "GB" ? "d-flex p-3 filter-opac" : "d-flex p-3 cursor-pointer hovered"} onClick={() => setCountry("GB")}>
                                    <GB title="United States" height={22+"px"} style={{marginRight: 10+"px"}}/>
                                    English
                                </div>
                            </div> 
                        : ""}
                    </div>
                </div>
            </div>
            <header className="position-relative">
                <div className="h-100 w-100 position-absolute">
                    <Navbar countrys={country}></Navbar>
                    <Header  country={country}></Header>
                </div>
            </header>
            <Services  country={country}></Services>
            <About  country={country}></About>
            <Why  country={country}></Why>
            <Work  country={country}></Work>
            {detail !== false ?
                <DetailProject  country={country} 
                    detail={details.find(work => work.desc === detail)}
                >
                </DetailProject>
            : null}
            <Product  country={country}></Product>
            <Contact  country={country}></Contact>
            <Whatabout  country={country}></Whatabout>
            <Footer  country={country}></Footer>
        </div>
    )
};

export default props => <Home {...props} />;