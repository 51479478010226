import React, { useState, useEffect, useMemo } from 'react';

import Icon from '@mdi/react';
import { mdiEmail, mdiChevronDown, mdiMapMarker, mdiPhone, mdiEmailEdit, mdiEmailCheck, mdiLoading } from '@mdi/js';

import Tag from './tag'

import Select from 'react-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

//SendMail
import emailjs from '@emailjs/browser';

//maps
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

//Translate
import Translate from './translate'

const Contact = ({name, desc, img, country}) => {
    const [error, setError] = useState(null);
    const [phone, setPhone] = useState(null);

    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState(null);

    const [fullName, setFullName] = useState("");

    const [mail, setMail] = useState("");

    const [text, setText] = useState("");

    const [devis, setDevis] = useState(false);

    const contactme = <Translate country={country} keys={"contactme"}/>

    const options = [
        { value: 'Site vitrine personalisé', label: 'Site vitrine personalisé' },
        { value: 'Site vitrine standard', label: 'Site vitrine standard' },
        { value: 'Création de maquette(s)', label: 'Création de maquette(s)' },
        { value: 'Développement spécifique', label: 'Développement spécifique' },
        { value: 'Autre', label: 'Autre' },
    ];

    const isEnter = function(e) {
        if (e.key === "Enter") {
            nextStep()
        }
    }

    const nextStep = function()  {
        switch (step) {
            case 1:
                selectedOption ? setStep(2) : null
                break;
            case 2:
                error === null && fullName !== "" ? setStep(3) : setError("Votre nom est trop court")
                break;
            case 3:
                error === null && mail !== "" ? setStep(4) : setError("Mail invalide (exemple@gmail.com)")
                break;
            case 4:
                error === null && phone ? setStep(5) : setError("Numéro invalide exemple: 33 6 14 12 54 88")
                break;
            case 5:
                error === null && text !== "" ? document.getElementById("submitedCommande").click() : setError("Votre message est trop court")
                break;
        
            default:
                break;
        }
    }

    const verifName = function(val)  {
        setFullName(val)
        if (val.length < 5) {
            setError("Votre nom est trop court")
        } else{
            setError(null)
        }
    }

    const verifMail = function(val)  {
        setMail(val)
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
            setError(null)
        } else{
            setError("Mail invalide (exemple@gmail.com)")
        }
    }

    const verifNum = function(val)  {
        setPhone(val)
        if (val.length === 11) {
            setError(null)
        } else{
            setError("Numéro invalide exemple: 33 6 14 12 54 88")
        }
    }

    const verifText = function(val)  {
        setText(val)
        if (val.length > 30) {
            setError(null)
        } else{
            setError("Votre message est trop court")
        }
    }

    document.addEventListener('devis', function(e) {
        setSelectedOption(options[e.detail])
        scroll(0, document.getElementById("contact").offsetTop - 110)
    }, false);

    const phrase = ["Type de devis", "Nom complet", "Adresse mail", "Numéro de telephone", "Votre demande"];

    //map
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAPV_-FXz0gPzPfbMMzn73a4Av3Yad91BQ"
    })

    const location = {
        lat: 43.56179192181299,
        lng: 1.4866986364211396,
    }

    const sendEmail = function(e) {
        e.preventDefault();
        setDevis(true)
    
        emailjs.sendForm('service_e7ox0fy', 'template_t3h5ivt', e.target, 'RVWL3p6lcBQEBA45w')
          .then((result) => {
              setDevis("good")
          }, (error) => {
              console.log(error.text);
          });
    };

    const center = useMemo(() => (location), [])

    return (
        <div className='text-center' id='contact' style={{marginTop: 100+"px"}}>
            <Tag name={contactme} color="blue" bgcolor="bgblue"></Tag>
            <div className='w-100 d-flex mt-5 contact2' style={{height: 400+"px"}}>
                <div className='d-flex'>
                    <div className='pp-5 shadow bg-white position-relative text-start pt-2 contactctn' style={{width: 600+"px"}}>
                        <div className={devis === true ? 'left-0 top-0 position-absolute w-100 h-100 bg-env bg-env-active align-items-center justify-content-center' : devis === false ? 'left-0 top-0 position-absolute w-100 h-100 bg-env align-items-center justify-content-center' : 'left-0 top-0 position-absolute w-100 h-100 bg-env bg-env-active align-items-center justify-content-center bg-success'}>
                            {devis === "good" ?
                                <div className='d-flex flex-column align-items-center text-white'>
                                    <div className='d-flex align-items-center bolden font-22'>
                                        <Icon path={mdiEmailCheck} className="m-1" height={70+"px"}/>
                                        Demande de devis envoyée!
                                    </div>
                                    Je vous réponds sous 48h.
                                </div>   
                                :
                                <div>
                                    <Icon path={mdiLoading} className="m-1 loader" height={50+"px"}/>
                                </div>
                        }
                        </div>
                        <h2>Contact</h2>
                        <div className='w-100'>
                            <form id='hiddenForm' onSubmit={(e) => sendEmail(e)}>
                                <input type="hidden" name='selectedOption' value={selectedOption ? selectedOption.label : null} />
                                <input type="hidden" name='mail' value={mail} />
                                <input type="hidden" name='phone' value={phone} />
                                <input type="hidden" name='text' value={text} />
                                <input type="hidden" name='fullName' value={fullName} />
                                <button id='submitedCommande' className='d-none' type="submit">
                                </button>
                            </form>
                            <h4 className='text-muted'>{phrase[step - 1]}</h4>
                            <div className='d-flex align-items-center' onKeyUp={(e) => isEnter(e)} style={{height: "115px"}}>
                                {step === 1 ?
                                    <Select
                                        className='w-100'
                                        id='option'
                                        defaultValue={selectedOption}
                                        value={selectedOption ? selectedOption : null}
                                        onChange={setSelectedOption}
                                        options={options}
                                    /> : null
                                }
                                {step === 2 ?
                                    <input type="text" class="form-control" maxLength="25" id="fullname" aria-describedby="emailHelp" value={fullName} onChange={(e) => verifName(e.target.value)} placeholder="Jean Dupond" autoFocus/> : null
                                }
                                {step === 3 ?
                                    <input type="email" class="form-control" id="mail" aria-describedby="emailHelp" value={mail} onChange={(e) => verifMail(e.target.value)} placeholder="Jean-Dupond@gmail.com" autoFocus/> : null
                                }
                                {step === 4 ?
                                    <div>
                                        <PhoneInput
                                            country={'fr'}
                                            value={phone}
                                            inputProps={{
                                                name: 'numéro',
                                                autoFocus: true,
                                                id: "phone"
                                            }}
                                            onChange={verifNum}
                                        />
                                    </div> : null
                                }
                                {step === 5 ?
                                    <textarea type="email" class="form-control" id="exampleInputEmail1" rows={4} aria-describedby="emailHelp" value={text} onChange={(e) => verifText(e.target.value)} placeholder="Je souhaiterais avoir un site vitrine pour mon commerce..." autoFocus/> : null
                                }
                                <svg xmlns="http://www.w3.org/2000/svg" id='next' onClick={() => nextStep()} className='m-2 cursor-pointer' width="47" height="26" viewBox="0 0 47 26" fill="none">
                                    <path d="M0 13.2444H44M44 13.2444L36.5714 2M44 13.2444L36.5714 24" stroke="black"/>
                                </svg>
                            </div>
                            <div className='w-100 mt-3 ligne-bottom d-flex align-items-center'>
                                <div style={{width: step*20 + "%"}}>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='text-danger'>
                                    {error}
                                </div>
                                <div style={{fontSize: "12px"}}>
                                    {step} / 5
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-100 p-3 bg-primary text-white bgb' style={{width: "285px"}}>
                            <div className='w-100 d-flex text-start align-items-center h-15'>
                                <Icon path={mdiMapMarker} className="m-1" height={30+"px"}/>
                                23 Av. Edouard Belin, 31400 Toulouse
                            </div>
                            <div className='w-100 d-flex text-start align-items-center h-15'>
                                <Icon path={mdiPhone} className="m-1" height={30+"px"}/>
                                06 12 10 05 72
                            </div>
                            <div className='w-100 d-flex text-start align-items-center h-15'>
                                <Icon path={mdiEmailEdit} className="m-1" height={30+"px"}/>
                                contact@gwendal-lefloch.com
                            </div>
                    </div>
                </div>
                <div className='map' style={{ height: '100%', width: '100%', minHeight:"400px" }}>
                    {isLoaded ?
                        <GoogleMap zoom={14} center={location} mapContainerClassName="w-100 h-100">
                            <Marker position={center}/>
                        </GoogleMap>
                    : "no"}
                </div>
            </div>
        </div>
    );
};

export default props => <Contact {...props}></Contact>;