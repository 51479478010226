import React from 'react';

const Card = ({name, desc, img}) => {
    return (
        <div className='rounded-big d-flex flex-column align-items-center shadow card-service m-3'>
            <img src={img} width="250px" className='img-card-service' alt={img} />
            <span className='text-center big'>
                {name}
            </span>
            <span className='text-center'>
                {desc}
            </span>
        </div>
    );
};

export default props => <Card {...props}></Card>;