import React from 'react';
import LOGO from '../assets/logo.png'

const Logo = () => {
    return (
        <div className='d-flex align-items-center logo cursor-pointer big' style={{fontSize: 25+'px'}}>
            <img src={LOGO} alt="logo" className='m-2' height={50+"px"} />
            Gwendal
            <span className="rounded-circle bg-danger m-2" style={{width: 12+"px", height: 12+"px"}}></span>
        </div>
    );
};

export default Logo;