import React, { useState, useEffect, useLayoutEffect } from 'react';

import firstpp from '../assets/firstpp.png'
import depp from '../assets/2PP.png'
import troispp from '../assets/troispp.png'

import check from '../assets/check.png'
import xperience from '../assets/xperience.png'
import insta from '../assets/insta.webp'
import Malt from '../assets/malt.png'
import linkedin from '../assets/LinkedIN.png'
import link from '../assets/link.png'
import message from '../assets/message.png'

//Translate
import Translate from './translate'

const Header = ({country}) => {
    let [pp, setPp] = useState(firstpp);
    let [cpt, setCpt] = useState(0);
    let pptest = firstpp

    const changePP = function()  {
        if (pptest === firstpp) {
            pptest = depp
        }  else if (pptest === depp) {
            pptest = troispp
        } else {
            pptest = firstpp
        }

        setPp(pptest)
    }

    useEffect(() => {
    console.log(country)
        const interval = setInterval(() => {
            changePP()
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='d-flex align-items-center logo header-ctn pr-3 pl-3 justify-content-between' style={{height: "calc(100% - 120px)"}}>
            <div className='w-50 left-header'>
                <h1 style={{fontSize: 50+"px"}}>
                    <Translate country={country} keys={"h1"}/>
                </h1>
                <h3 className='text-muted pb-4 subhead'>
                    <Translate country={country} keys={"subtitle"}/>
                </h3>
                <div className='button-quote button-quote2 text-white p-3 rounded-pill position-relative bolden pressed' style={{width: "max-content"}} onClick={() => scroll(0, document.getElementById("services").offsetTop - 110)}>
                    <span>
                        <Translate country={country} keys={"find"}/>
                    </span>
                </div>
                <div className='rs d-flex'>
                    <a href="https://www.linkedin.com/in/gwendal-le-floch-66a3361b7/" target="_blank" rel="noopener noreferrer" style={{paddingTop: "10px", marginLeft: "0.5rem"}}>
                        <img src={linkedin} alt="" width={46+"px"} />
                    </a>
                    <a href="https://www.instagram.com/gwendiish/" target="_blank" rel="noopener noreferrer" style={{paddingTop: "10px"}}>
                        <img src={insta} alt="" width={46+"px"} />
                    </a>
                    <a href="https://www.malt.fr/profile/gwendallefloch" target="_blank" rel="noopener noreferrer" style={{paddingTop: "10px"}}>
                        <img src={Malt} alt="malt" className='rounded-circle' width={46+"px"} />
                    </a>
                </div>
            </div>
            <div className='right-header position-relative p-5' style={{width: 700+"px"}}>
                <img className='position-absolute link' src={link} alt="" />
                <img className='w-100' src={pp} alt="background pp"/>
                <div className={pp === firstpp ? 'position-absolute bg-white cards-header cards-header1 shadow d-flex flex-column align-items-center pt-4 pb-2' : 'position-absolute bg-white cards-header cards-header1 shadow d-flex flex-column align-items-center pt-4 pb-2 animation-none'}>
                    <img src={check} className="position-absolute ico-header" width="80px" alt="" />
                    <div className='pt-4 m-2 mb-0' style={{fontSize: 34+"px"}}>
                        7+
                    </div>
                    <div className='text-muted' style={{fontWeight: 400, fontSize: 16+"px"}}>
                        <Translate country={country} keys={"project"}/>
                    </div>
                </div>
                <div className={pp === depp ? 'position-absolute bg-white cards-header-hor cards-header2 shadow d-flex align-items-center' : 'position-absolute bg-white cards-header-hor cards-header2 shadow d-flex align-items-center animation-none'}>
                    <img src={xperience} className="ico-header" width="80px" alt="" />
                    <div className='d-flex flex-column w-75'>
                        <div style={{fontSize: 34+"px"}}>
                            2+
                        </div>
                        <div className='text-muted' style={{fontWeight: 400, fontSize: 16+"px"}}>
                            <Translate country={country} keys={"exp"}/>
                        </div>
                    </div>
                </div>
                <div className={pp === troispp ? 'position-absolute bg-white cards-header cards-header3 shadow d-flex flex-column align-items-center pt-4 pb-2' : 'position-absolute bg-white cards-header cards-header3 shadow d-flex flex-column align-items-center pt-4 pb-2 animation-none'}>
                    <img src={message} className="position-absolute ico-header" width="80px" alt="" />
                    <div className='pt-4 m-2 mb-0' style={{fontSize: 34+"px"}}>
                        5
                    </div>
                    <div className='text-muted' style={{fontWeight: 400, fontSize: 16+"px"}}>
                        <Translate country={country} keys={"positif"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;